const ErrorNotFoundLogo = () => (
  <svg
    width="450"
    height="220"
    viewBox="0 0 450 220"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 0C5.37258 0 0 5.37258 0 12V60H450V12C450 5.37258 444.627 0 438 0H12ZM450 70H0V220H450V70Z"
      fill="#E0E0E0"
    />
    <circle cx="30" cy="30" r="8" fill="#CCCCCC" />
    <circle cx="54" cy="30" r="8" fill="#CCCCCC" />
    <circle cx="78" cy="30" r="8" fill="#CCCCCC" />
    <path
      d="M275.002 151.262V135.653C275.002 134.179 275.101 132.551 275.299 130.769L260.482 151.262H275.002ZM288.103 151.262V155.981C288.103 156.421 287.96 156.806 287.674 157.136C287.388 157.444 286.981 157.598 286.453 157.598H282.097V169.247H275.002V157.598H254.872C254.322 157.598 253.838 157.433 253.42 157.103C253.002 156.751 252.738 156.322 252.628 155.816L251.77 151.691L274.375 121.496H282.097V151.262H288.103Z"
      fill="#CCCCCC"
    />
    <path
      d="M243.251 145.387C243.251 149.545 242.8 153.164 241.898 156.244C241.018 159.302 239.786 161.832 238.202 163.834C236.64 165.836 234.781 167.332 232.625 168.322C230.491 169.29 228.181 169.774 225.695 169.774C223.209 169.774 220.899 169.29 218.765 168.322C216.653 167.332 214.816 165.836 213.254 163.834C211.692 161.832 210.471 159.302 209.591 156.244C208.711 153.164 208.271 149.545 208.271 145.387C208.271 141.207 208.711 137.588 209.591 134.53C210.471 131.472 211.692 128.942 213.254 126.94C214.816 124.938 216.653 123.453 218.765 122.485C220.899 121.495 223.209 121 225.695 121C228.181 121 230.491 121.495 232.625 122.485C234.781 123.453 236.64 124.938 238.202 126.94C239.786 128.942 241.018 131.472 241.898 134.53C242.8 137.588 243.251 141.207 243.251 145.387ZM234.836 145.387C234.836 141.933 234.583 139.073 234.077 136.807C233.571 134.541 232.889 132.737 232.031 131.395C231.195 130.053 230.227 129.118 229.127 128.59C228.027 128.04 226.883 127.765 225.695 127.765C224.529 127.765 223.396 128.04 222.296 128.59C221.218 129.118 220.261 130.053 219.425 131.395C218.589 132.737 217.918 134.541 217.412 136.807C216.928 139.073 216.686 141.933 216.686 145.387C216.686 148.841 216.928 151.701 217.412 153.967C217.918 156.233 218.589 158.037 219.425 159.379C220.261 160.721 221.218 161.667 222.296 162.217C223.396 162.745 224.529 163.009 225.695 163.009C226.883 163.009 228.027 162.745 229.127 162.217C230.227 161.667 231.195 160.721 232.031 159.379C232.889 158.037 233.571 156.233 234.077 153.967C234.583 151.701 234.836 148.841 234.836 145.387Z"
      fill="#CCCCCC"
    />
    <path
      d="M185.232 151.262V135.653C185.232 134.179 185.331 132.551 185.529 130.769L170.712 151.262H185.232ZM198.333 151.262V155.981C198.333 156.421 198.19 156.806 197.904 157.136C197.618 157.444 197.211 157.598 196.683 157.598H192.327V169.247H185.232V157.598H165.102C164.552 157.598 164.068 157.433 163.65 157.103C163.232 156.751 162.968 156.322 162.858 155.816L162 151.691L184.605 121.496H192.327V151.262H198.333Z"
      fill="#CCCCCC"
    />
  </svg>
);

export default ErrorNotFoundLogo;
