'use client';

import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Link from 'next/link';
import ErrorNotFoundLogo from '@/app/modules/common/components/icons/error-not-found-logo';
import { palette } from '@/styles/palette';

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  backgroundColor: theme.palette.gray['50'],
  paddingBottom: '42px',
}));

const StyledBtn = styled(Button)(() => ({
  backgroundColor: '#FF5C00',
  textTransform: 'capitalize',
  color: 'white',
  fontSize: '16px',
  padding: '8px',
  height: '52px',
  marginTop: '24px !important',
  borderRadius: '12px',
  '&:hover': {
    backgroundColor: '#F45B04',
  },
  minWidth: '336px',
}));

export default function NotFound() {
  return (
    <Container>
      <ErrorNotFoundLogo />
      <Typography
        variant="h1"
        sx={{
          marginTop: '58px',
        }}
      >
        Page not found
      </Typography>
      <Typography sx={{ marginTop: '8px', color: palette.gray['500'] }}>
        The page you’re trying to access does not exist.
      </Typography>
      <Link href="/portal/overview">
        <StyledBtn>Back to home</StyledBtn>
      </Link>
    </Container>
  );
}
